<div class="layout-sidebar">
    <div class="flex flex-column h-auto mt-4 lg:mt-0">
        <div
            [ngClass]="{
                hidden: true,
                'lg:flex': true,
                'align-items-center': true,
                'mx-auto': true,
                'justify-content-between': true,
                'py-3': true,
                'flex-shrink-0': true,
                'px-4': !isSlimMode,
            }"
        >
            <div
                [ngClass]="{
                    flex: true,
                    'gap-4': true,
                    'flex-column': isSlimMode,
                    'flex-row': !isSlimMode,
                }"
            >
            <button *ngIf="isSlimMode" (click)="toggleMenuMode()">
                <i class="pi pi-angle-double-right"></i>
            </button>
            <img
                src="assets/logos/Logomark.png"
                alt="Logomark"
                [ngStyle]="isSlimMode ? {'width.px': 31.1, 'height.px': 25.98} : {}"
            />
            <img
                *ngIf="!isSlimMode"
                [src]="getLogoSrc()"
                alt="Logo"
                [ngStyle]="{'width.px': 109, 'height.px': 42}"
            />
            <button *ngIf="!isSlimMode" (click)="toggleMenuMode()">
                <i class="pi pi-angle-double-left"></i>
            </button>

            </div>
        </div>

        <div class="px-4 py-2 my-2 w-22rem lg:w-auto app-menu-container">
            <app-menu></app-menu>
        </div>

        <!-- Added user info and logout icon at sthe bottom -->
        <div class="sidebar-footer">
            <div class="user-info flex justify-content-between ">
                <div *ngIf="!isSlimMode || isMobile" class="overflow-hidden">
                    <span class="user-name">{{ storageService.getUser()?.vNombreCompleto }}</span>
                    <span class="user-email">{{ storageService.getUser()?.vEmail }}</span>
                </div>
                <button class="logout-btn" (click)="pressLogout()">
                    <i class="pi pi-sign-out"></i>
                </button>
            </div>
        </div>
    </div>
</div>
