import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/interfaces/usuario';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private userKey = 'user';
    private user: Usuario | null = null;

    constructor() {
        const storedUser = localStorage.getItem(this.userKey);
        this.user = storedUser ? JSON.parse(storedUser) : null;
    }

    setUser(data: Usuario) {
        this.user = data;
        localStorage.setItem(this.userKey, JSON.stringify(data));
    }

    getUser(): Usuario | null {
      return this.user;
    }

    clearUser() {
        this.user = null;
        localStorage.removeItem(this.userKey);
    }
}
