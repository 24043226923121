import { Component, OnInit } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  styleUrl: './app.footer.component.scss'
})
export class AppFooterComponent implements OnInit {

  public rolID?: number;

  constructor(public layoutService: LayoutService) { }

  get colorScheme(): string {
    return this.layoutService.config().colorScheme;
  }

  ngOnInit(): void {
    // usuario guardado en el local storage
    const LSUser = localStorage.getItem("user");

    // si el usuario existe, que lo parsee de string a objeto
    if(LSUser) {
      const user = JSON.parse(LSUser);

      // asignar el id del rol a la variable rolID
      this.rolID = user.iRolClav;
    }
  }
}
