import {
    HttpClient,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { AppConfig } from 'src/app/config/app-config';
import { StorageService } from './storage.service';

const URL_API = 'http://localhost:8080/account-api/api/cuenta';

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
});

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private apiGetAccount = AppConfig.cuentaAPI.baseURL;
    private apiGetClient = AppConfig.solicitudAPI.baseURL;

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) {}

    isLoggedIn: boolean = false;

    login(vUsuario: string, vContrasena: string): Observable<boolean> {
        const body = {
            vUsuario: vUsuario,
            vContrasena: vContrasena,
        };
        return this.http
            .post<any>(`${AppConfig.apiUrl}/cuenta/iniciar-sesion`, body, {
                headers,
            })
            .pipe(
                map((response: any) => {
                    localStorage.setItem('auth_token', response.token);
                    this.storageService.setUser(response.usuario);
                    this.isLoggedIn = true;
                    return true;
                }),
                catchError((error: any) => {
                    this.isLoggedIn = false;
                    return of(false);
                })
            );
    }

    getObtenerRol(): Observable<any> {
        return this.http.get<any>(
            `${AppConfig.cuentaAPI.baseURL}/obtener-rol`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'auth_token'
                    )}`,
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }
        );
    }

    validateToken(): Observable<boolean> {
        return this.http
            .get<any>(`${AppConfig.apiUrl}/cuenta/autenticar`, { headers })
            .pipe(
                map((response: any) => {
                    return true;
                }),
                catchError((error: any) => {
                    this.isLoggedIn = false;
                    return of(false);
                })
            );
    }

    logout(): void {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('provider');
        this.isLoggedIn = false;
    }

    isAuthenticated(): boolean {
        return this.isLoggedIn;
    }

    // realizar una  request para obtener el usuario logueado
    getAccount(): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });

        return this.http.get(`${this.apiGetAccount}/obtener-usuario`, {
            headers,
        });
    }

    // Petición para obtener los datos del cliente de acuerdo al id
    getClient(idClient: number): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });

        return this.http.get(
            `${this.apiGetClient}/obtener-cliente/${idClient}`,
            { headers }
        );
    }

    getAllUsuarioByRol(
        rol: number = 2
    ):Observable<any>{
        return this.http.get<any>(`${this.apiGetAccount}/obtener-usuarios-rol?rol=${rol}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        }).pipe(
            map((response: any) =>
                response.usuarios.map((usuario: any) => ({
                    label: usuario.vNombreCompleto,
                    value: usuario.iUsuaClav,
                }))
            )
        );
    }

    getNombreUsuario(idClient: number): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });

        return this.http.get(
            `${this.apiGetAccount}/obtener-nombre-usuario?id=${idClient}`,
            { headers }
        );
    }
}
