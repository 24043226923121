import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '../pages/service/account.service';
import { firstValueFrom } from 'rxjs';

export const httpGuard: CanActivateFn = async (route, state) => {
    const token = localStorage.getItem('auth_token') || '';
    const router = inject(Router);
    const accountService = inject(AccountService);
    const url = router.createUrlTree(['login']);

    if (token != '') {
        if (!await firstValueFrom(accountService.validateToken())) {
            return url;
        }
        return true;
    } else {
        return url;
    }
};
