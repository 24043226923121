<div class="layout-container" [ngClass]="containerClass" style="overflow: unset; background-color: #f9fafb;">
    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper">
        <div class="layout-content" style="overflow: unset; background-color: #f9fafb;">
            <div class="layout-content-inner">
                <app-breadcrumb></app-breadcrumb>
                <router-outlet></router-outlet>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
    <app-config></app-config>
</div>
