<nav class="layout-breadcrumb ml-3" *ngIf="showBreadcrumb">
    <ol>
      <ng-container *ngIf="(breadcrumbs$ | async)">
        <ng-template ngFor let-item let-last="last" let-length="index" [ngForOf]="breadcrumbs$ | async">
          <li class="layout-breadcrumb-chevron">
            <a [routerLink]="[item.url]">
              {{ item.label }}
            </a>
          </li>
          <li *ngIf="!last || length < 1" class="layout-breadcrumb-chevron">
            <img src="assets/layout/images/chevron-right.svg" alt="breadcrumb-chevron">
          </li>
        </ng-template>
      </ng-container>
    </ol>
  </nav>
  <nav class="layout-breadcrumb ml-3" *ngIf="!showBreadcrumb">
    <ol>
      <ng-container *ngIf="(breadcrumbs$ | async)">
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
          <li *ngIf="item.label !== 'Home' && item.label !== 'Tickets' && item.label !== 'Solicitudes'" class="layout-breadcrumb-chevron">
            <a (click)="goBack()" class="flex align-content-center gap-2">
              <img src="assets/layout/images/arrow-left.svg" alt="breadcrumb-chevron">
              Back
            </a>
          </li>
        </ng-template>
      </ng-container>
    </ol>
  </nav>
