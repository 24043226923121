import { Component, OnInit } from '@angular/core';
import { AccountService } from '../pages/service/account.service';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(public layoutService: LayoutService, private accountService: AccountService) {}

    ngOnInit() {
        this.accountService.getObtenerRol().subscribe((rolData: any) => {
            this.model = [
                {
                    label: rolData.rol.toLowerCase() === 'administrador_de_ticket' ? 'Tickets' : rolData.rol.toLowerCase() === 'abogado' ? 'Solicitudes' : 'Home',
                    icon: rolData.rol.toLowerCase() === 'administrador_de_ticket' ? 'tickets-logo-sidebar.svg' : 'home-line.svg',
                    url: '/',
                },
            ];
        });
    }

}
