export const AppConfig = {
    // LOCALES
    apiVerifyStatus: 'http://3.142.49.117:8080/account-API/api/cuenta/verificar-estatus',
    apiCreateAccount: 'http://3.142.49.117:8080/account-API/api/cuenta/crear-cuenta',
    getCatalogos: {
        baseUrl: 'http://3.142.49.117:8080/account-API/api/cuenta/obtener-'
    },
    cuentaAPI: {
        // baseURL: "http://localhost:8081/account-API/api/cuenta"
        baseURL: "http://3.142.49.117:8080/account-API/api/cuenta"
    },
    solicitudAPI:{
        // baseURL: "http://localhost:8080/solicitud-api/api/solicitud"
        baseURL: "http://3.142.49.117:8080/solicitud-api/api/solicitud"
    },
    documentosAPI:{
        // baseURL: "http://localhost:8082/documento-api/api/documentos"
        baseURL: "http://3.142.49.117:8080/documento-api/api/documentos"
    },
    // API
    apiUrl: 'http://3.142.49.117:8080/account-API/api',

    //SeguriData
    getSeguriDataAPI: {
        //Ya se traera el token desde la URL
        apiSeguriDataSign: 'https://feb.seguridata.com/ssignrest/user?',
        apiSeguriDataMultilateral: 'https://feb.seguridata.com/ssignrest/multilateral/',
        apiSeguriDataMultilateralGetHash: 'https://feb.seguridata.com/ssignrest/multilateral/getHash/',
        apiSeguriDataSignWiHash: 'https://feb.seguridata.com/ws-rest-seguritools_Firma_pkcs7/signWithHash?doPKCS7=true',
        apiSeguriDataMultilateralUpdate: 'https://feb.seguridata.com/ssignrest/multilateral/update/',
        apiSeguriDataFinalize: 'https://feb.seguridata.com/ssignrest/multilateral/finalize/',
        apiSeguriDataGetStatus: 'https://feb.seguridata.com/ssignrest/multilateral/status/'
    },
};
