<div class="custom-menu">
    <div class="menu-header">
        <img
            src="assets/qualitas-logo.png"
            alt="Qualitas Logo"
            class="menu-logo"
        />
        <div class="menu-toggle">
            <i class="pi pi-angle-left"></i>
        </div>
    </div>
    <ul class="menu-items">
        <li class="menu-item">
            <a href="#">
                <i class="pi pi-home menu-icon"></i>
                <span class="menu-label">Home</span>
            </a>
        </li>
    </ul>
    <div class="menu-footer">
        <div class="user-info">
            <span class="user-name">Bianca Méndez</span>
            <span class="user-email">biancaqualitas.com</span>
        </div>
        <div class="logout-icon">
            <i class="pi pi-sign-out"></i>
        </div>
    </div>
</div>
