<div *ngIf="isMobile" class="layout-topbar">
    <a class="app-logo" routerLink="/">
        <img alt="app logo" src="../../assets/logos/Logomark.svg">
    </a>

    <div class="topbar-search">
    </div>

    <div class="topbar-profile">
            <button
            #menubutton
                class="topbar-profile-button p-link"
                type="button"
                (click)="onMenuButtonClick()"
            >
                <i class="pi pi-align-left"></i>
            </button>

            <!-- Include the AppSidebarComponent here -->
            <app-sidebar
            #sidebar
                [isMobile]="isMobile"
                [ngClass]="{ hidden: !layoutService.state.staticMenuMobileActive }"
            ></app-sidebar>
        </div>


</div>
