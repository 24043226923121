import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { httpGuard } from './helpers/http.guard';
import { AppLayoutComponent } from './layout/app.layout.component';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/home/home.module')
                    .then(m => m.HomeModule)
            },
            { path: 'solicitud', data: { breadcrumb: 'Solicitud' }, loadChildren: () => import('./pages/solicitud/solicitud.module').then(m => m.SolicitudModule) },
            { path: 'detalle-contrato/:id', data: { breadcrumb: 'Detalle de contrato' }, loadChildren: () => import('./pages/detalle-contrato/detalle-contrato.module').then(m => m.DetalleContratoModule) },
        ], canActivate: [httpGuard],
    },
    { path: 'registro', loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule) },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule) },
    { path: 'notfound', loadChildren: () => import('./pages/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: 'firma-externa/:infToken',   loadChildren: () => import('./pages/firma-externa/firma-externa.module').then(m => m.FirmaExternaModule) },
    { path: 'mensaje-firma/:token/:tipo', loadChildren: () => import('./pages/mensaje/mensaje.module').then( m => m.MensajeModule)},
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
