import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './helpers/http.interceptor';
import { AppLayoutModule } from './layout/app.layout.module';

@NgModule({
    declarations: [AppComponent],
    imports: [AppLayoutModule, AppRoutingModule, TableModule, TooltipModule, HttpClientModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy},  provideHttpClient(withInterceptors([AuthInterceptor])),MessageService ],
    bootstrap: [AppComponent],
})
export class AppModule {}
