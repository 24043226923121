<div class="layout-footer" *ngIf="rolID === 1">
    <div class="footer-start">
        <span class="texto-footer">¿Tienes dudas sobre el proceso? Descarga la
            <a class="enlace-descarga" href="assets/layout/documents/politicas_procedimientos_legales.pdf"
                download="politicas_procedimientos_legales.pdf">
                política de procedimientos</a> y nuestra
            <a class="enlace-descarga" href="assets/layout/documents/guia_rapida_politica.docx" download="guia_rapida_politica.docx">
                guía rápida</a> aquí</span>
    </div>
    <div class="footer-end">
        <span class="texto-footer"></span>
        <!-- <span>© Your Organization</span> -->
    </div>
</div>
