import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export const AuthInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const token = localStorage.getItem('auth_token');
    //URLS que no deben llevar token
    const excludedUrls = [
        'https://feb.seguridata.com/' // Añade más URLs aquí si es necesario
      ];

    if(excludedUrls.some(url => req.url.startsWith(url)) ){
        return next(req);
    }
    if (token) {
        const request = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next(request);
      }
    return next(req);
};
