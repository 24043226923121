import { Location } from '@angular/common'; // Importar Location
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

interface Breadcrumb {
  label: string;
  url?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnInit {
  showBreadcrumb: boolean = false;
  private lastBreadcrumbBase: string | null = null; // Variable para almacenar el último breadcrumb base
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router, private location: Location) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const root = this.router.routerState.snapshot.root;
      const state = this.router.getCurrentNavigation()?.extras.state;
      const breadcrumbs: Breadcrumb[] = [];

      if (state?.['breadcrumbBase']) {
        // Guardar el último breadcrumb base
        this.lastBreadcrumbBase = state['breadcrumbBase'].toLowerCase();
        this.addBreadcrumbFromState(state['breadcrumbBase'], breadcrumbs);
      } else if (this.router.url === '/' && this.lastBreadcrumbBase) {
        // Si la URL es '/', utiliza el último breadcrumb base almacenado
        this.addBreadcrumbFromState(this.lastBreadcrumbBase, breadcrumbs);
      }

      this.addBreadcrumb(root, [], breadcrumbs);
      this._breadcrumbs$.next(breadcrumbs);
    });
  }

  ngOnInit(): void {
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  private checkScreenSize(): void {
    this.showBreadcrumb = window.innerWidth > 768;
  }

  private addBreadcrumbFromState(base: string, breadcrumbs: Breadcrumb[]) {
    const breadcrumbLabel = this.getBreadcrumbLabel(base);
    if (['home', 'tickets', 'solicitudes'].includes(base.toLowerCase())) {
      breadcrumbs.push({
        label: breadcrumbLabel,
        url: '/',
      });
    } else {
      breadcrumbs.push({
        label: breadcrumbLabel,
        url: `/${base.toLowerCase()}`
      });
    }
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    const routeUrl = parentUrl.concat(route.url.map(url => url.path));
    const breadcrumb = route.data['breadcrumb'];

    if (breadcrumb && !breadcrumbs.find(b => b.url === '/' + routeUrl.join('/'))) {
      if (routeUrl[0] === 'solicitud') {
          breadcrumbs.push({
              label: 'Home',
              url: '/'
          });
      }
      breadcrumbs.push({
        label: this.getBreadcrumbLabel(breadcrumb),
        url: '/' + routeUrl.join('/')
      });
    }

    if (route.firstChild) {
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getBreadcrumbLabel(base: string): string {
    if (base.toLowerCase() === 'crear-contrato') {
      return 'Solicitudes';
    }
    return base
      .split('/')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  // Función para manejar la navegación hacia atrás
  goBack(): void {
    if (this.location.path() !== '') {
        this.location.back();
      } else {
        this.router.navigate(['/']);
      }
  }
}
