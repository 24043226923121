import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
    menu: MenuItem[] = [];
    model: any[] = [];

    // variable de suscripcion para mobile
    isMobileSubscription: Subscription = new Subscription();

    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton', { static: false }) menuButton!: ElementRef;
    @ViewChild('sidebar', { static: false }) sidebar!: ElementRef;

    // variable para mobile
    isMobile: boolean = false;

    constructor(public layoutService: LayoutService) {}

    ngOnInit(): void {
        // Suscripcion al observable de  isMobile$
        this.isMobileSubscription = this.layoutService.isMobile$.subscribe(
            (isMobile) => {
                this.isMobile = isMobile;
            }
        );

    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    // Escucha para clics en el documento
    @HostListener('document:click, window:resize', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (
            this.isMobile &&
            this.layoutService.state.staticMenuMobileActive &&
            !this.menuButton.nativeElement.contains(event.target) && // El clic no es en el botón del menú
            !this.sidebar.nativeElement.contains(event.target) // El clic no es en el menú
        ) {
            this.layoutService.onMenuToggle(); // Cierra el menú
        }
    }

    get layoutTheme(): string {
        return this.layoutService.config().layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }
}
